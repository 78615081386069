$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$font-size-base: 1.2rem;
$font-size-xl: 6.25rem;
$font-size-l: $font-size-xl * 0.6;
$font-size-m: $font-size-xl * 0.4;
$font-size-s: $font-size-xl * 0.3;
$font-size-xs: $font-size-xl * 0.25;

$h1-font-size: $font-size-xl;
$h2-font-size: $font-size-l;
$h3-font-size: $font-size-m;
$h3-font-weight: 600;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer / 4,
        2: $spacer / 2,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4
);

// links
$link-color: #0CA7C6;

// accordion
$accordion-button-active-bg: transparent;
$accordion-button-focus-border-color: black;
$accordion-border-color: black;
$accordion-border-width: 2px;
$accordion-button-focus-box-shadow: 0;
$accordion-button-active-color: none;

// navbar
$navbar-light-color: black;