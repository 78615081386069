$footer-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

footer {
  background-color: #2A2E30;
  color: white;
}

.footer-collapse-button{
  &:hover{
    cursor: pointer;
  }


  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left; // Reset button style
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: auto;
    content: "";
    background-image: escape-svg($footer-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
  }
}

.footer-logo{
  img{
    max-width: 120px;

    @include media-breakpoint-down(md) {
      max-width: 100px;
    }
  }
}