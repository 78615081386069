.accordion-button {
  @include font-size($font-size-m);

  @include media-breakpoint-down(sm) {
    @include font-size($font-size-s);
  }

  .text-wrapper{
    overflow: hidden;
    white-space:nowrap;
    margin-right: 5px;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 0;
  }
  text-transform: uppercase;
  font-weight: 600;
  border-left: 0;
  border-right: 0;

  &:not(.collapsed) {
    @include font-size($font-size-l);
    @include transition(font-size 0.5s);
    @include media-breakpoint-down(sm) {
      @include font-size($font-size-m);
    }
  }

  &.collapsed {
    @include transition(font-size 0.5s);
  }
}

.accordion-collapse, .accordion-item {
  border-left: 0;
  border-right: 0;
}

.accordion-body{
  .btn-link{
    padding: 0!important;
    text-decoration: none;
  }
}

$custom-colors: (
  "citrus": #C5D935,
  "clementine": #CA9326,
  "melon": #79AA80,
  "exotic": #912F69,
  "dades": #B0584D,
  "tomato": #B51F24,
  "beans": #0E8642,
  "potatoe": #432626,
  "herbs": #87C87F
);

@each $name, $value in $custom-colors {
  .color-#{$name} {
    color: $value;
  }
}