.nav-link {
  @include font-size($font-size-s);
  font-weight: 500;
  color: black;
}

.nav-item {
  padding-right: 1rem;
  cursor: pointer;
}

.navbar-toggler {
  border: none;
  &:focus{
    box-shadow: none;
  }
}

.nav-lang{
  right: 0;
  bottom: 0;
  background-color: #B40000;
  z-index: 999;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  a{
    color: white;
  }

  .btn-link {
    color: white;
    text-decoration: none;
    vertical-align: inherit;
    padding: 0;
  }
}

.list-pipes li + li::before {
  content: " | ";
}