@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "accordion";
@import "carousel";
@import "service";
@import "nav";
@import "employee";
@import "footer";

html:root {
  scroll-behavior: auto;
}

body {
  font-family: 'Inter', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-weight: 600;
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: $font-size-m;
  }
}

h2 {
  @include media-breakpoint-down(sm) {
    font-size: $font-size-s;
  }
}

a {
  text-decoration: none;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}

.loader-bg {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  backdrop-filter: blur(5px);
  .loader{
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.responsive-footer-logo {
  img {
    width: 100px;
  }
}
