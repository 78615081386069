/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  max-height: 100vh;

  h1, h2 {
    margin-bottom: 0.5rem;
  }
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  max-height: 100vh;
}

.carousel-item > img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}