.employee {
  .employee-card{
    .lead{
      font-size: $font-size-s;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
    }

    .info{
      font-size: $font-size-xs;
    }
    .tel {
      font-size: $font-size-m;
    }

    @include media-breakpoint-down(sm) {
      h3{
        font-size: 1.5rem;
        margin-bottom: 0;
      }
      .info {
        dl {
          margin-bottom: 0.5rem;
        }
      }

      .tel,.email  {
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .square{
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    dl {
      font-size: 1rem;
    }
  }
}