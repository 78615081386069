.svc-item {
  h4 {
    text-transform: none;
    @include media-breakpoint-up(sm) {
      padding-bottom: 1rem;
    }
  }
}

.service-col {
  p {
    margin-bottom: 0;
  }
  >h3, >img{
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include media-breakpoint-up(sm) {
      height: 18rem;
    }
  }

  h3 {
    margin-bottom: 0;
    line-height: 8rem;
    @include media-breakpoint-up(sm) {
      line-height: 16rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: $font-size-l;
    }
  }

  @include media-breakpoint-down(sm) {
    img {
      width: 50%;
    }
  }

}